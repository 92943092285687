export const AVAILABLE_DATES_LIST = {
  noLimits: {
    text: 'Без ограничений',
    value: 0,
  },
  onlyPastAndToday: {
    text: 'Только прошедшие и сегодня',
    value: 1,
  },
  onlyFutureAndToday: {
    text: 'Только будущие и сегодня',
    value: 2,
  },
  period: {
    text: 'Заданный период',
    value: 3,
    onlygGraphCalendar: true,
  },
  excludeRelativeDate: {
    text: 'Относительно даты создания заявки',
    value: 4,
    onlygGraphCalendar: true,
  },
  onlyPast: {
    text: 'Только прошедшие',
    value: 5,
  },
  onlyFuture: {
    text: 'Только будущие',
    value: 6,
  },
};

export const RANGE_VIEW_MODE_NAME = 'range';

export const GRAPH_CALENDAR_NAME = 'graphCalendar';
