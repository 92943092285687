const getInputControlList = (elements) => {
  const list = [];
  elements.forEach((el) => {
    if (el.classType === 'input') {
      list.push(el);
    } else if (el.classType === 'group' && el.childs) {
      list.push(...getInputControlList(el.childs));
    } else if (el.classType === 'table' && el.childs) {
      list.push(...getInputControlList(el.childs));
    }
  });
  return list;
};

export default getInputControlList;
