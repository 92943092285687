<template>
  <esmp-modal
    v-model="isShowedSettings"
    width="800"
  >
    <template #header>
      Настройки контрола:<br>
      {{ viewName }}
    </template>
    <validation-observer ref="form" tag="div">
      <template v-if="localElement">
        <div class="tech-name">
          <esmp-icon name="id" />
          <span>Техническое имя {{ localElement.techId }}</span>
        </div>
        <template v-for="setting in sortLocalElementSettings">
          <component
            v-if="isShowed(setting)"
            :key="`${localElement.techId}_${setting.id || setting.techId}`"
            :is="getSettingComponent(setting)"
            :setting="setting"
            :id="localElement.id || localElement.techId"
            :default-values="defaultValues"
            :class-type="localElement.classType"
            :input-control-list="inputControlList"
            :mask="mask"
            @input="updateSetting"
          />
        </template>
      </template>
    </validation-observer>
    <template #footer>
      <esmp-button @click="saveSettings">
        Ок
      </esmp-button>
      <esmp-button view="outline" @click="resetSettings">
        Отмена
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import Hub from '@/plugins/event-hub';
import settingsMap from '@/components/service-form/settings.map';
import controlsMap from '@/components/service-form/controls.map';
import cloneDeep from 'lodash/cloneDeep';
import { AVAILABLE_DATES_LIST, RANGE_VIEW_MODE_NAME } from '@/constants/calendar';

const SSimpleTextValue = () => import('@/components/service-form/control-settings/SSimpleTextValue.vue');
const SSwitch = () => import('@/components/service-form/control-settings/SSwitch.vue');
const SName = () => import('@/components/service-form/control-settings/SName.vue');
const SHintText = () => import('@/components/service-form/control-settings/SHintText.vue');
const SMinMax = () => import('@/components/service-form/control-settings/SMinMax.vue');
const SAddToDesc = () => import('@/components/service-form/control-settings/SAddToDesc.vue');
const SMask = () => import('@/components/service-form/control-settings/SMask.vue');
const SExternalSystemField = () => import('@/components/service-form/control-settings/SExternalSystemField.vue');
const SValues = () => import('@/components/service-form/control-settings/SValues.vue');
const SDefaultValue = () => import('@/components/service-form/control-settings/SDefaultValue.vue');
const SShowDateByDefault = () => import('@/components/service-form/control-settings/SShowDateByDefault.vue');
const SDatesToShowType = () => import('@/components/service-form/control-settings/SDatesToShowType.vue');
const SViewMode = () => import('@/components/service-form/control-settings/SViewMode.vue');
const SExcludeHolidays = () => import('@/components/service-form/control-settings/SExcludeHolidays.vue');
// eslint-disable-next-line
const SExcludeMinMaxRelativeDate = () => import('@/components/service-form/control-settings/SExcludeMinMaxRelativeDate.vue');
const SAddMoreGroup = () => import('@/components/service-form/control-settings/SAddMoreGroup.vue');
const SMinMaxDate = () => import('@/components/service-form/control-settings/SMinMaxDate.vue');
const SResourceGroup = () => import('@/components/service-form/control-settings/SResourceGroup.vue');
// eslint-disable-next-line
const SAddResourceOrRoleConfig = () => import('@/components/service-form/control-settings/SAddResourceOrRoleConfig.vue');
const SContactInfoType = () => import('@/components/service-form/control-settings/SContactInfoType.vue');
const SReplaceDirection = () => import('@/components/service-form/control-settings/SReplaceDirection.vue');
const SCmdbObject = () => import('@/components/service-form/control-settings/s-cmdb-object/SCmdbObject.vue');
const SSearchType = () => import('@/components/service-form/control-settings/s-search-type/SSearchType.vue');
const SIsUseHint = () => import('@/components/service-form/control-settings/SIsUseHint.vue');
const SMinMaxRangeDate = () => import('@/components/service-form/control-settings/SMinMaxRangeDate.vue');
const SDatabase = () => import('@/components/service-form/control-settings/s-database/SDatabase.vue');
const SScaleOptions = () => import('@/components/service-form/control-settings/SScaleOptions.vue');
const SInput = () => import('@/components/service-form/control-settings/SInput.vue');
const SCalculatedField = () => import('@/components/service-form/control-settings/SCalculatedField.vue');
const SDocument = () => import('@/components/service-form/control-settings/SDocument.vue');
const SAddMoreDocument = () => import('@/components/service-form/control-settings/SAddMoreDocument.vue');
const SFormData = () => import('@/components/service-form/control-settings/SFormData.vue');
const SNumberInput = () => import('@/components/service-form/control-settings/SNumberInput.vue');

export default {
  name: 'SettingsForm',
  components: {
    SSimpleTextValue,
    SSwitch,
    SName,
    SHintText,
    SMinMax,
    SAddToDesc,
    SMask,
    SExternalSystemField,
    SValues,
    SDefaultValue,
    SShowDateByDefault,
    SDatesToShowType,
    SViewMode,
    SExcludeHolidays,
    SExcludeMinMaxRelativeDate,
    SAddMoreGroup,
    SMinMaxDate,
    SResourceGroup,
    SAddResourceOrRoleConfig,
    SContactInfoType,
    SReplaceDirection,
    SCmdbObject,
    SSearchType,
    SIsUseHint,
    SMinMaxRangeDate,
    SDatabase,
    SScaleOptions,
    SInput,
    SCalculatedField,
    SDocument,
    SAddMoreDocument,
    SFormData,
    SNumberInput,
  },
  props: {
    inputControlList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowedSettings: false,
      localElement: null,
      originElement: null,
      resourceValues: [],
    };
  },
  computed: {
    sortLocalElementSettings() {
      return [...this.localElement.settings].sort((a, b) => (a.sortOrder - b.sortOrder));
    },
    viewName() {
      const controller = controlsMap[this.localElement?.classType];
      return controller ? controller.viewName : 'Неизвестный тип';
    },
    defaultValues() {
      if (this.localElement?.classType === 'resourceField') {
        return this.resourceValues;
      }
      const listValues = this.localElement.settings.find((el) => el.techName === 'values');
      return listValues?.values
        ? [{ name: 'Значение не выбрано', techId: 'default' }, ...listValues.values]
        : [];
    },
    mask() {
      if (this.localElement?.classType === 'input') {
        const maskSetting = this.localElement.settings.find((el) => el.techName === 'maskEnabled');
        if (maskSetting?.value) {
          return maskSetting.childs?.find((el) => el.techName === 'mask')?.value;
        }
      }
      return undefined;
    },
  },
  methods: {
    updateSetting(setting) {
      this.localElement.settings = this.localElement.settings.map((s) => {
        if (setting.techId === s.techId) return setting;
        return s;
      });
    },
    getSettingComponent(setting) {
      return settingsMap[setting.techName]?.component || 'div';
    },
    saveSettings() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          Hub.$emit('update-element-settings', this.localElement);
          this.originalElement = null;
          this.localElement = null;
          this.isShowedSettings = false;
        } else {
          this.$EsmpNotify.$show(
            'Поля формы заполнены некорректно',
            'error',
          );
        }
      });
    },
    resetSettings() {
      this.localElement = cloneDeep(this.originalElement);
      this.isShowedSettings = false;
    },
    isShowed(setting) {
      if (['minRangeDate', 'maxRangeDate'].includes(setting.techName)) {
        const datesToShowType = this.localElement.settings.find((el) => el.techName === 'viewMode');
        return datesToShowType?.value === RANGE_VIEW_MODE_NAME;
      }
      if (['minDate', 'maxDate'].includes(setting.techName)) {
        const datesToShowType = this.localElement.settings.find((el) => el.techName === 'datesToShowType');
        return datesToShowType?.value === AVAILABLE_DATES_LIST.period.value;
      }
      if (['minRelativeDate', 'maxRelativeDate'].includes(setting.techName)) {
        const datesToShowType = this.localElement.settings.find((el) => el.techName === 'datesToShowType');
        return datesToShowType?.value === AVAILABLE_DATES_LIST.excludeRelativeDate.value;
      }
      if (setting.techName === 'defaultValue') {
        return this.localElement.classType !== 'resourceField'
          || (this.localElement.classType === 'resourceField' && this.defaultValues);
      }
      return true;
    },
  },
  created() {
    Hub.$on('open-element-settings-modal', (element) => {
      this.localElement = cloneDeep(element);
      this.originalElement = cloneDeep(element);
      this.isShowedSettings = true;
    });
    Hub.$on('set-resource-values', (data) => {
      if (this.localElement.techId === data.id) {
        this.resourceValues = data.values;
      }
    });
  },
  beforeDestroy() {
    Hub.$off('open-element-settings-modal');
    Hub.$off('set-resource-values');
  },
};
</script>

<style lang="scss" scoped>
.tech-name {
  display: flex;
  align-items: center;
}
.tech-name,
::v-deep .setting-item {
  width: 100%;
  padding: 10px;
  background-color: $color-black-op-05;
  border-radius: $base-border-radius;
  margin-top: 10px;

  &:first-child {
     margin: 0;
   }

  .esmp-select-head {
    background-color: $color-black-op-05;
  }
}
</style>
