<template>
  <div class="service-form">
    <div class="service-form__elements">
      <div v-if="isSearching" class="list-group list-group--no-drag">
        <template v-if="elementsList && elementsList.length">
          <h2 class="list-group__title">
            Результаты поиска:
          </h2>
          <transition-group type="transition" name="flip-list">
            <template
              v-for="(el, index) in elementsList"
            >
              <control-item
                :key="`searched_${el.techId}_${Number(new Date())}`"
                :element="el"
                :dependencies="deps"
                @on-remove="removeControl"
                @on-copy="onCopy(el, index)"
                @on-update="update"
              />
            </template>
          </transition-group>
        </template>
        <h2 v-else>
          По вашему запросу ничего не найдено
        </h2>
      </div>
      <template v-else>
        <div v-if="welcomeText" class="list-group list-group--no-drag">
          <control-item
            key="welcomeText"
            :element="{
              content: welcomeText,
              isWelcome: true,
            }"
            type="page"
          />
        </div>
        <draggable
          :class="{ 'no-empty': elementsList.length > 0, 'list-group': true }"
          group="controls"
          :list="elementsList"
          @input="(data) => elementsList = data"
        >
          <transition-group type="transition" name="flip-list">
            <template
              v-for="(el, index) in elementsList"
            >
              <control-item
                :key="el.techId"
                :element="el"
                :dependencies="deps"
                @on-remove="removeControl"
                @on-copy="onCopy(el, index)"
                @on-update="update"
              />
            </template>
          </transition-group>
        </draggable>
        <div v-if="finishText" class="list-group list-group--no-drag">
          <control-item
            key="finishText"
            :element="{ content: finishText }"
            type="page"
          />
        </div>
      </template>
      <settings-form :input-control-list="inputControlList" />
      <confirm-modal />
    </div>
    <div v-show="!isSearching" class="service-form__controls">
      <control-list :controls="controls" :is-control-sorting="isControlSorting" />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import ControlItem from '@/components/service-form/ControlItem.vue';
import ControlList from '@/components/control-list';
import copyElement from '@/components/service-form/helpers/copyElement';
import SettingsForm from '@/components/service-form/SettingsForm.vue';
import ConfirmModal from '@/components/service-form/ConfirmModal.vue';
import Hub from '@/plugins/event-hub';
import getInputControlList from '@/components/hm-form/helpers/getInputControlList';

export default {
  model: {
    prop: 'elements',
    event: 'input',
  },
  name: 'ServiceForm',
  components: {
    SettingsForm,
    ControlItem,
    draggable,
    ControlList,
    ConfirmModal,
  },
  props: {
    controls: {
      type: Array,
      required: true,
    },
    elements: {
      type: Array,
      default: () => [],
    },
    dependencies: {
      type: Array,
      default: () => [],
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    welcomeText: {
      type: String,
      default: '',
    },
    finishText: {
      type: String,
      default: '',
    },
    isControlSorting: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    const globalState = {};
    Object.defineProperty(globalState, 'elements', {
      enumerable: true,
      get: () => this.elementsList,
    });
    return { globalState };
  },
  computed: {
    elementsList: {
      get() {
        return this.elements;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    deps() {
      return this.dependencies;
    },
    inputControlList() {
      return getInputControlList(this.elements);
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    removeControl(techId) {
      const index = this.elementsList.findIndex((parent) => parent.techId === techId);
      if (index >= 0) {
        this.elementsList.splice(index, 1);
      }
    },
    onCopy(el, index) {
      const copy = copyElement(cloneDeep(el));

      this.elementsList.splice(index + 1, 0, copy);
    },
    update({ id, data }) {
      const index = this.elementsList.findIndex((parent) => parent.techId === id);
      if (index >= 0) {
        this.$set(this.elementsList, index, data);
      }
    },
  },
  created() {
    Hub.$on('update-element-settings', (el) => {
      const index = this.elementsList.findIndex((parent) => parent.techId === el.techId);
      if (index >= 0) {
        this.$set(this.elementsList[index], 'settings', el.settings);
      }
    });
  },
  beforeDestroy() {
    Hub.$off('update-element-settings');
  },
};
</script>

<style lang="scss" scoped>
$controls-offset: 40px;

.service-form {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: stretch;
  &__elements {
    display: flex;
    width: calc(100% - #{$controls-width} - #{$controls-offset});
  }
  &__controls {
    width: $controls-width;
    margin-left: $controls-offset;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 50px;
  width: 100%;
  > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 50px;
  }
  & + & {
    margin-top: 20px;
  }
  &--no-drag ::v-deep .control {
    cursor: default;
  }
  &__title {
    margin-bottom: math.div($controls-offset, 2);
  }
}
</style>
